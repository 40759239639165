import numeral from 'numeral';
import { isBlank } from 'adready-api/helpers/common';
import moment from 'moment';
import {
  VISITS,
  CURRENCY,
  PERCENTAGE,
  DECIMAL_PERCENTAGE,
  FREQUENCY,
  DECIMAL,
  DEMO_DOMAIN_PART,
  DEMO_ACCOUNTS,
  DEMO_ADVERTISERS,
} from '~/constant';
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';

const VALUE_FORMAT_MAPPING = {
  impressions: VISITS,
  spend: CURRENCY,
  cpm: CURRENCY,
  visits: VISITS,
  cpa: CURRENCY,
  revenue: CURRENCY,
  roas: PERCENTAGE,
  frequency: FREQUENCY,
  pm: DECIMAL,
  reach: '',
  incrementalreachpercentage: DECIMAL_PERCENTAGE,
  incrementality: PERCENTAGE,
  brandlift: PERCENTAGE,
  vcr: PERCENTAGE,
  videoCompletions: VISITS,
  ctr: DECIMAL_PERCENTAGE,
  clicks: VISITS,
};

export function getValueFormat(valueType) {
  return VALUE_FORMAT_MAPPING[valueType.toLowerCase()] || 'VISITS';
}

export function toTitleCase(s = '') {
  return s
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
}

export const convertEpochToNYTimezone = (date) => {
  // console.log('date1: ', date);
  // console.log('convertEpochToNYTimezone');
  // const d = date ? new Date(date) : new Date();
  // const dTime = d.getTime() + d.getTimezoneOffset() * 60000;
  // const nyTimeString = new Date(dTime).toLocaleString('en-US', { timeZone: 'America/New_York' });
  // const nyTime = new Date(nyTimeString);
  // console.log('date2: ', nyTime);
  // return nyTime;
  return date;
};

export const offsetDate = (offsetDays) => {
  const newDate = new Date(new Date().setDate(new Date().getDate() - offsetDays));
  return newDate;
};

export const generateDefaultDates = () => {
  const startDate = offsetDate(31);
  const endDate = offsetDate(2);
  const compareStartDate = offsetDate(32);
  const compareEndDate = offsetDate(3);
  return { startDate, endDate, compareStartDate, compareEndDate };
};

export function formatHour(s) {
  let hour = 0;
  if (s === '0') {
    hour = 12;
  } else {
    hour = `${s > 12 ? s - 12 : s}`;
  }
  return `${hour} ${s >= 12 ? 'PM' : 'AM'}`;
}

export const capitalizeStringSentence = (str = '') => {
  if (str !== '') {
    const arr = str.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  }
  return '';
};

export function formatNumberAsLargeNumber(value, format = '') {
  if (!format) {
    return value;
  }
  const isPercentageFormat = format && format.endsWith('%');
  const valueClone = isPercentageFormat ? value / 100 : value;
  const num = numeral(valueClone);
  return num.format(format);
}

export function formatFileSize(bytes) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  return `${(bytes / 1024 ** i).toFixed(2) * 1} ${units[i]}`;
}

export function insertAt(array, index, ...elements) {
  array.splice(index, 0, ...elements);
}

export function isAnyBlank(object, fieldList) {
  for (let i = 0; i < fieldList.length; i++) {
    if (isBlank(object[fieldList[i]])) {
      return true;
    }
  }
  return false;
}

export function getDomainPartsFromHost(hostname) {
  const host = hostname;
  const domainParts = host.split('.');
  const subDomain = domainParts.shift();
  const rootDomain = domainParts.join('.');
  return { host, subDomain, rootDomain };
}

export function isDemoInstance() {
  const { subDomain } = getDomainPartsFromHost(window.location.hostname);
  return subDomain.toLowerCase().includes(DEMO_DOMAIN_PART);
}

export function isFlamingoInstance() {
  const { subDomain } = getDomainPartsFromHost(window.location.hostname);
  return subDomain.toLowerCase().includes('flamingo');
}

export function isStandardDashInstance() {
  const { subDomain } = getDomainPartsFromHost(window.location.hostname);
  return subDomain.toLowerCase().includes('app');
}

export function toFixedNumber(num, digits) {
  if (digits === 0) {
    return Math.round(num);
  }
  const pow = 10 ** digits;
  return Math.round(num * pow) / pow;
}

export function dateDiff(s, e) {
  if (isBlank(s) || isBlank(e)) {
    return 0;
  }
  const startDate = moment(s);
  const endDate = moment(e);
  return endDate.diff(startDate, 'days');
}

export function isAdvertiserSetupCompleted(advertiser) {
  if (!advertiser?.selfServe) {
    return true;
  }

  const accountSetup =
    advertiser &&
    advertiser.selfServe &&
    advertiser.accountSetup &&
    advertiser.accountSetup.accountInfoAvailable &&
    advertiser.accountSetup.paymentInfoAvailable;

  return accountSetup;
}

export function formatMetricValue(label, value, includeLabel = false) {
  if (isBlank(label) && label !== 'REACH') {
    return '';
  }
  if (isBlank(value)) {
    value = 0;
  }
  const displayLabel = label.toUpperCase();
  if (label === 'REACH' && includeLabel === true) {
    return displayLabel;
  }
  const valueType = getValueFormat(label);
  const val =
    label === 'ROAS' || label === 'VCR'
      ? formatNumber(value, valueType)
      : formatNumberAsFullNumber(value, valueType);
  return includeLabel ? `${displayLabel}: ${val}` : val;
}

export function scrollToElement(elementId, alignTo = 'top') {
  const el = document.getElementById(elementId);
  if (!el) {
    return;
  }

  if (alignTo === 'top') {
    el.scrollIntoView(true);
    return;
  }

  el.scrollIntoView();
}
export function deepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2; // Compare primitive values directly
  }

  // Check if both objects are null (since typeof null === 'object')
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check if one object is null and the other is not
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and values are equal
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function addDaysToDate(date, count) {
  date.setDate(date.getDate() + count);
  return date;
}

export function jsonArrayToCsv(array) {
  const header = Object.keys(array[0]);

  // handle null or undefined values here
  const replacer = (key, value) => value ?? '';

  const rows = array.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
  );

  // join header and body, and break into separate lines
  const csv = rows.join('\r\n');
  return csv;
}

export function downloadCSVFile(items, downloadFileName = 'export.csv') {
  const csv = jsonArrayToCsv(items);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, downloadFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', downloadFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const RANGE_CAMPAIGN_TO_DATE = 13;
export const RANGE_CUSTOM = 0;

export function formatDate(date, dateFormat = 'yyyy-MM-DD') {
  return !isBlank(date) ? moment(new Date(date)).format(dateFormat) : '';
}

export const getCampaignDateRange = (campaigns = [], decideEndDate = false) => {
  let selectedCampaigns = campaigns.filter((val) => val.checked);
  if (!selectedCampaigns.length) {
    selectedCampaigns = campaigns;
  }
  const startDates = selectedCampaigns.map((filterVal) => filterVal.startDate);
  const minStartDate = startDates.sort()[0];
  let endDate = new Date(new Date().setDate(new Date().getDate() - 1));
  let dateRangeOption = RANGE_CAMPAIGN_TO_DATE;

  if (decideEndDate) {
    const endDates = selectedCampaigns.map((filterVal) => filterVal.endDate);
    let maxEndDate = endDates.sort().reverse()[0];
    const yesterday = formatDate(endDate);
    if (maxEndDate >= yesterday) {
      maxEndDate = yesterday;
    } else {
      dateRangeOption = RANGE_CUSTOM;
    }
    endDate = new Date(maxEndDate);
  }
  const compareEndDate = new Date(new Date(endDate).setDate(endDate.getDate() - 1));
  const notAvailable = minStartDate === undefined || endDate === undefined;
  return {
    startDate:
      minStartDate !== undefined ? convertEpochToNYTimezone(new Date(minStartDate)) : minStartDate,
    endDate: endDate !== undefined ? convertEpochToNYTimezone(endDate) : minStartDate,
    dateRangeOption,
    compareStartDate:
      minStartDate !== undefined ? convertEpochToNYTimezone(new Date(minStartDate)) : minStartDate,
    compareEndDate:
      compareEndDate !== undefined ? convertEpochToNYTimezone(compareEndDate) : compareEndDate,
    notAvailable,
  };
};

export const getQueryParams = (url) => {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.forEach((param) => {
    const [key, val] = param.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export function filterAccountsAndAdvertisersForDemo(userAccounts) {
  if (!isDemoInstance()) {
    return userAccounts;
  }

  const userAccountsCopy = JSON.parse(JSON.stringify(userAccounts));
  const accountKeys = Object.keys(DEMO_ACCOUNTS).map((key) => parseInt(key, 10));
  const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));

  // Keep only accounts that are mentioned in DEMO_ACCOUNTS map
  const filteredUserAccounts = userAccountsCopy.filter((ua) => accountKeys.includes(ua.id));

  filteredUserAccounts.forEach((ua) => {
    // Keep only advertisers that are mentioned in DEMO_ADVERTISERS map
    const filteredAdvertisers = ua?.advertisers.filter((adv) => advertiserKeys.includes(adv.id));
    ua.advertisers = filteredAdvertisers ? JSON.parse(JSON.stringify(filteredAdvertisers)) : [];
  });

  return filteredUserAccounts;
}
